import '../scss/app.scss';

import './bootstrap';

const worker = new Worker(new URL('./workers/worker.js', import.meta.url));

document.addEventListener("DOMContentLoaded", function () {

    var w;

    function startWorker() {
        if (typeof(Worker) !== "undefined") {
            if (typeof(w) == "undefined") {
                // Use Vite-specific syntax to load the worker
                w = new Worker(new URL('./workers/worker.js', import.meta.url), { type: 'module' });
            }
            w.onmessage = function(event) {
                document.getElementById("result").innerHTML = event.data;
            };
        } else {
            document.getElementById("result").innerHTML = "Sorry! No Web Worker support.";
        }
        return "startWorker";
    }

    function stopWorker() {
        if (w) {
            w.terminate();
            w = undefined;
        }
        return "stopWorker";
    }

// Event listeners
    let resultOutput = document.getElementById('result');
    if (resultOutput) {
        document.getElementById('startworker').addEventListener('click', function() {
            startWorker();
        });
        document.getElementById('stopworker').addEventListener('click', function() {
            stopWorker();
        });
    }
});
